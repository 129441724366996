/* eslint-disable react-hooks/exhaustive-deps */
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import React, { useEffect, useRef, useState } from "react";
import "@adyen/adyen-web/dist/adyen.css";
import { CLIENT_KEY, FRONTEND_URL, ENVIRONMENT } from "./../../config";
import { useNavigate, useLocation } from "react-router-dom";

import { calculateMinorUnit, depositCodeMapping } from "./../../helpers/utils";
import { createPaymentSessionAPI, getNotificationsAPI } from "../../api/Adyen";
import { updatePaymentAPI } from "../../api/PaymentAPI";
import { updatePaymentRequest } from "../../api/PaymentRequestAPI";
import {
  submitBatchAPI,
  createMiscArChargeAPI,
  getTaxesAPI,
  getNextReferenceNumberAPI,
} from "../../api/DockMasterAPI";
import {
  getPaymentReceivedNotification,
  getPaymentReceivedCustomerReceipt,
  padWithZeros,
  roundTo,
} from "../../helpers/utils";
import { sendEmailAPI } from "./../../api/SMSEmailRequestsAPI";
import { depositCode } from "../../helpers/utils";

const Payment = () => {
  const batchIds = []; // Initialize an array to collect batch IDs
  const aydenRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentSession, setPaymentSession] = useState(null);
  const [paymentPayload, setPaymentPayload] = useState(null);

  const paymentDetails = location.state?.paymentDetails;
  const tenantInfo = location.state?.tenantInfo;

  const getNextReferenceNumber = async (tenantId, customerId) => {
    try {
      const referenceNumber = await getNextReferenceNumberAPI(
        tenantId,
        customerId,
      );
      return referenceNumber; // Assuming the response format includes the reference number directly
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("sessionId");
    const redirectResult = queryParams.get("redirectResult");

    if (sessionId && redirectResult) {
      handleRedirectResult(sessionId, redirectResult);
    }
  }, [location]);

  useEffect(() => {
    if (aydenRef.current && paymentSession === null) {
      createPaymentSession();
    }
  }, [aydenRef.current]);

  useEffect(() => {
    if (paymentPayload) {
      const interval = setInterval(() => {
        checkPaymentStatus(interval);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [paymentPayload, paymentSession]);

  if (
    !paymentDetails ||
    !paymentDetails.amount ||
    !paymentDetails.shopper_reference ||
    !paymentDetails.merchantId ||
    !paymentDetails.submerchantId ||
    !paymentDetails.locationCode ||
    !paymentDetails.tenantId ||
    !paymentDetails.uuid ||
    !paymentDetails.paymentID
  ) {
    return "No Payment details provided";
  }
  if (paymentDetails.payment_methods.length === 0) {
    return "No Payment methods Enabled";
  }

  const configuration = {
    environment: ENVIRONMENT, // TODO: Change to 'live' for the live environment.
    clientKey: paymentDetails.TenantClientKey,
    analytics: {
      enabled: true,
    },
    onPaymentCompleted: (result, component) => {
      if (result.resultCode === "Authorised") {
        if (paymentDetails.paymentRequest) {
          if (
            paymentDetails.amount >= paymentDetails.paymentRequest.totalamount
          ) {
            paymentDetails.paymentRequest.paymentstatus = "PAID";
            updatePaymentRequest(paymentDetails.paymentRequest);
          } else {
            paymentDetails.paymentRequest.paymentstatus = "PARTIALLY PAID";
            updatePaymentRequest(paymentDetails.paymentRequest);
          }
        }
        setPaymentPayload(result);
      }
    },
    onError: (error, component) => {
      if (paymentDetails.paymentRequest) {
        paymentDetails.paymentRequest.paymentstatus = "FAILED";
        updatePaymentRequest(paymentDetails.paymentRequest);
      }
      navigate("/error");
    },
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: paymentDetails.RequireBillingAddress,
      },
    },
  };


  const sendBatchRequestToDockMaster = async (
    payload,
    postBatch,
    locationCode,
  ) => {
    try {
      return await submitBatchAPI(
        locationCode,
        paymentDetails.tenantId,
        payload,
        postBatch,
      );
    } catch (error) {
      throw new Error("There was a problem with batch submit.");
    }
  };

  const createMiscCharge = async (miscChargeReq) => {
    try {
      return await createMiscArChargeAPI(miscChargeReq);
    } catch (error) {
      throw new Error("There was a problem with create Charge");
    }
  };

  /*  const getTax = async () => {
    try {
      return await getTaxesAPI(
        paymentDetails.tenantId,
        paymentSession.message.reference,
        paymentDetails.shopper_reference,
        paymentDetails.locationCode,
        paymentDetails.surcharge,
      );
    } catch (error) {
      throw new Error("There was a problem with get Tax");
    }
  };
*/
  const checkPaymentStatus = async (interval) => {
    try {
      const jsonResponse = await getNotificationsAPI({
        merchant_reference: paymentSession.message.reference,
        transaction_id: paymentDetails.uuid,
      });
      
      if (jsonResponse && jsonResponse[0].success) {
        clearInterval(interval);

        let miscChargeReq = {};
        let miscChargeRes = {};
        const invoicesByLocation = new Map();

        // Group invoices by location
        if (paymentDetails.invoices && paymentDetails.invoices.length > 0) {
          paymentDetails.invoices.forEach((inv) => {
            if (!invoicesByLocation.has(inv.locationCode)) {
              invoicesByLocation.set(inv.locationCode, []);
            }
            invoicesByLocation.get(inv.locationCode).push(inv);
          });
        }
        //TODO: IF convenience fee shouldn't be created when there is a MiscDeposit and PutMiscDepositonAccount is off, then this logic needs to be moved or modified to not create an invoice for the convenience fee.
        // Add the convenience fee to the first location's invoice list if applicable
        if (paymentDetails.convenienceFee !== 0) {
          let convenienceFeeLocationCode;
          //if no invoices are on the payment request, use the default pay location to process the convenience fee
          if (
            paymentDetails.invoices &&
            paymentDetails.invoices.length > 0 &&
            paymentDetails.invoices[0].locationCode
          ) {
            convenienceFeeLocationCode =
              paymentDetails.invoices[0].locationCode;
          } else {
            convenienceFeeLocationCode = paymentDetails.locationCode;
          }
          miscChargeReq = {
            customer_id: paymentDetails.shopper_reference,
            charge_date: new Date().toISOString().split("T")[0],
            charge_amount: paymentDetails.convenienceFee,
            charge_description: "convenienceFee",
            location_code: convenienceFeeLocationCode, // this is the location the convenience fee will be posted to
            is_convenience_fee: true,
            user_id: paymentDetails.tenantId,
          };
          miscChargeRes = await createMiscCharge(miscChargeReq);
          if (miscChargeRes && miscChargeRes.invoiceId) {
            if (!invoicesByLocation.has(convenienceFeeLocationCode)) {
              invoicesByLocation.set(convenienceFeeLocationCode, []);
            }
            invoicesByLocation.get(convenienceFeeLocationCode).push({
              id: miscChargeRes.invoiceId,
              amount: paymentDetails.convenienceFee,
              locationCode: convenienceFeeLocationCode,
            });
          } else {
            throw new Error("Failed to create miscellaneous charge invoice ID");
          }
        }

        // Handle deposits without invoices
        if (depositCode.includes(paymentDetails.transaction_type)) {
          let depositLocationCode;
          if (paymentDetails.invoices && paymentDetails.invoices.length > 0) {
            depositLocationCode = paymentDetails.invoices[0].locationCode;
          } else {
            depositLocationCode =
              paymentDetails.paymentRequest.tenant_info.DefaultPayLoc;
          }
          if (!invoicesByLocation.has(depositLocationCode)) {
            invoicesByLocation.set(depositLocationCode, []);
          }
          invoicesByLocation.get(depositLocationCode).push({
            id: paymentDetails.depositInvoiceId,
            amount: paymentDetails.depositAmount,
            locationCode: depositLocationCode,
            depositType: depositCodeMapping[paymentDetails.transaction_type],
          });
        }

        let isFirstBatch = true;
        let batchPayloadReq = [];
        let batchInv = [];
        let batchPayloadRes = null;
        const allInvoices = [];
        const referenceNumbers = []; // Array to collect all reference numbers
        const locations = []; // Array to collect all locations
        let referenceNum;
        // Process each location's invoices and deposits separately
        for (const [locationCode, invoices] of invoicesByLocation) {
          // Filter out invoices without invoiceid
          batchInv = invoices
            .filter((inv) => inv.id)
            .map((inv) => {
              const result = {
                invoiceId: inv.id,
                locationCode: inv.locationCode,
                depositType: inv.depositType || "",
                paymentAmt: inv.amount,
              };
              if (inv.customerId) {
                result.customerId = inv.customerId; // Only add customerId if it's truthfully defined
              }
              return result;
            });

          // Spread operator to push all elements of batchInv into allInvoices
          allInvoices.push(...batchInv);
          if (!locations.includes(locationCode)) {
            locations.push(locationCode); // Collect unique locations
          }

          //const taxes = await getTax();

          if (!isFirstBatch) {
            referenceNum = await getNextReferenceNumber(
              paymentDetails.tenantId,
              paymentDetails.shopper_reference,
            );
            if (!referenceNum) {
              throw new Error("Failed to retrieve the next reference number");
            }
            referenceNum = `CTP-${referenceNum.referenceNumber}`; // Format the reference number correctly for subsequent batches
          } else {
            referenceNum = paymentSession.message.reference; // Use the initial reference number for the first batch
          }
          referenceNumbers.push(referenceNum); // Collect all reference numbers
          // Calculate locationTotal
          let locationTotal = batchInv.reduce(
            (sum, inv) => sum + inv.paymentAmt,
            0,
          );
          let hasMiscDeposit = false;
          if (isFirstBatch) {
            const depositAmount = paymentDetails.depositAmount || 0;
            // Determine if any invoice lacks an ID, and log such instances
            const hasNoId = invoices.some((inv) => {
              if (!inv.id) {
                return true;
              }
              return false;
            });
            // Check if there is a deposit amount and all invoices lack a non-empty depositType, but only if invoices array is not empty OR if there is atleast one deposit without an ID
            // This handles all the cases when there is a MiscDeposit. Case 1: Invoice without ID. This only occurs when there is a MD payment request
            // Case 2: There is a deposit amount but none of the invoices have a depositType. This case only happens when there is a Misc Deposit added to invoice payments
            // Case 3: there is a deposit amount, the PaymentDetails.invoices array is empty BUT there are no invoices with a depositType in the generated invoices array (this one should always have an invoice for any deposits that are not Misc Deposits)
            if (
              (depositAmount > 0 &&
                paymentDetails.invoices.length > 0 &&
                paymentDetails.invoices.every((inv) => !inv.depositType)) ||
              hasNoId ||
              (depositAmount > 0 &&
                invoices.length > 0 &&
                invoices.every((inv) => !inv.depositType))
            ) {
              hasMiscDeposit = true; // Set flag indicating there's a miscellaneous deposit
              locationTotal += depositAmount; // Increment locationTotal by the depositAmount
            }

            batchPayloadReq = [
              {
                customerId: paymentDetails.shopper_reference,
                referenceNum: referenceNum,
                payType: tenantInfo.PayType ?? "CTP",
                totalPayment: roundTo(
                  locationTotal + paymentDetails.surcharge,
                  2,
                ), // Use locationTotal instead of paymentDetails.amount
                statementDesc:
                  referenceNum +
                  " " +
                  paymentDetails.transaction_type +
                  " " +
                  padWithZeros(paymentDetails.shopper_reference, 10) +
                  " " +
                  paymentDetails.fullName,
                ccAuthCode: paymentPayload.resultCode,
                ccTransactionID: paymentSession.psp,
                ccTransactionTimeStamp: new Date().toISOString(),
                ccSurcharge: roundTo(paymentDetails.surcharge, 2),
                ccSurchargeTax: 0,
                ccSurchargeTaxSchema: "",
                ccSurchargeTaxIds: [],
                invPayments: batchInv,
              },
            ];
          } else {
            batchPayloadReq = [
              {
                customerId: paymentDetails.shopper_reference,
                referenceNum: referenceNum,
                payType: tenantInfo.PayType ?? "CTP",
                totalPayment: roundTo(locationTotal, 2), // Use locationTotal instead of paymentDetails.amount
                statementDesc:
                  referenceNum +
                  " " +
                  paymentDetails.transaction_type +
                  " " +
                  padWithZeros(paymentDetails.shopper_reference, 10) +
                  " " +
                  paymentDetails.fullName,
                ccAuthCode: paymentPayload.resultCode,
                ccTransactionID: paymentSession.psp,
                ccTransactionTimeStamp: new Date().toISOString(),
                ccSurcharge: 0,
                ccSurchargeTax: 0,
                ccSurchargeTaxSchema: "",
                ccSurchargeTaxIds: [],
                invPayments: batchInv,
              },
            ];
          }

          isFirstBatch = false;
          console.log(
            `Sending batch request for location: ${locationCode}`,
            batchPayloadReq,
          );
          batchPayloadRes = null;
          const invoiceHasNegativeAmount = invoices.some(
            (invoice) => invoice.amount < 0,
          );

          if (
            paymentDetails.putMiscDepositsOnAccount == true ||
            (paymentDetails.putMiscDepositsOnAccount == false &&
              paymentDetails.depositAmount <= 0) ||
            hasMiscDeposit == false
          ) {
            if (
              paymentDetails.applyCreditsToInvoice == false &&
              invoiceHasNegativeAmount
            ) {
              // skip batch request in case we have negative amount invoice and applyCreditsToInvoice is false
            } else {
              batchPayloadRes = await sendBatchRequestToDockMaster(
                batchPayloadReq,
                paymentDetails.postBatch,
                locationCode,
              );
              if (batchPayloadRes && batchPayloadRes.batchId) {
                batchIds.push(batchPayloadRes.batchId); // Collect batch IDs
              }
            }
          }
        }
        //console.log("All batches processed with IDs:", batchIds);

        const lastFourRaw =
          jsonResponse[0]?.additionalData?.cardSummary ?? null;
        const lastFour = lastFourRaw === "undefined" ? null : lastFourRaw;

        const allBatchIds = batchIds.join(", "); // Join all batch IDs into a single string

        const allReferenceNumbers = referenceNumbers.join(", "); // Join all reference numbers into a single string
        const allLocations = locations.join(", "); // Join all locations into a single string
        const formattedInvoices = allInvoices.map((inv) => {
          const result = {
            id: inv.invoiceId,
            locationCode: inv.locationCode,
            depositType: inv.depositType,
            amount: inv.paymentAmt,
          };
          if (inv.customerId) {
            result.customerId = inv.customerId; // Only add customerId if it's truthfully defined
          }
          return result;
        });
        await updatePaymentStatus(
          "PROCESSED",
          "PAID",
          paymentPayload,
          jsonResponse,
          miscChargeReq,
          miscChargeRes,
          batchPayloadReq,
          batchPayloadRes,
          allInvoices.map((inv) => inv.invoiceId),
          jsonResponse[0].eventCode,
          jsonResponse[0].pspReference,
          lastFour,
          paymentDetails.amount,
          allBatchIds, // Pass the concatenated batch IDs
          allReferenceNumbers, // Pass the concatenated reference numbers
          allLocations, // Pass the concatenated locations
        );

        const paymentDetailInformation = formattedInvoices
          .filter((inv) => inv.customerId) // Only include invoices with a non-falsy customer ID
          .map((inv) => ({
            reference: inv.id,
            description: "",
            amount: inv.amount,
            action: "Cash Receipt",
          }));
        if (paymentDetails.convenienceFee !== 0) {
          paymentDetailInformation.push({
            reference: "Fee",
            description: "Convenience Fee",
            amount: paymentDetails.convenienceFee,
            action: "Cash Receipt",
          });
        }
        if (paymentDetails.surcharge !== 0) {
          paymentDetailInformation.push({
            reference: "Fee",
            description: "Surcharge",
            amount: paymentDetails.surcharge,
          });
        }
        if (paymentDetails.depositAmount > 0) {
          const transactionType =
            depositCodeMapping[paymentDetails.transaction_type] || "Deposit";
          const invoiceId = paymentDetails.depositInvoiceId || "No Invoice ID";
          paymentDetailInformation.push({
            reference: "Deposit",
            description: `${transactionType} : ${invoiceId}`,
            amount: paymentDetails.depositAmount,
          });
        }

        const locationApiRequestInformation = formattedInvoices
          .filter((inv) => inv.customerId) // Only include invoices with a non-falsy customer ID
          .map((inv) => ({
            location: inv.locationCode,
            batch_ref: batchPayloadRes?.batchId ?? "",
            receipt_number: inv.id,
            amount: inv.amount,
          }));


        if (
          paymentDetails.convenienceFee !== 0 ||
          paymentDetails.surcharge !== 0
        ) {
          locationApiRequestInformation.push({
            location: paymentDetails.locationCode,
            batch_ref: "Fee",
            receipt_number: "",
            amount: paymentDetails.convenienceFee + paymentDetails.surcharge,
          });
        }

        const applyPaymentInformation = formattedInvoices
          .filter((inv) => inv.customerId) // Only include invoices with a non-falsy customer ID
          .map((inv) => ({
            location: inv.locationCode,
            invoice: inv.id,
            depositType: "Cash Receipt",
            amount: inv.amount,
          }));
        if (
          paymentDetails.convenienceFee !== 0 ||
          paymentDetails.surcharge !== 0
        ) {
          applyPaymentInformation.push({
            location: paymentDetails.locationCode,
            invoice: "Fee",
            depositType: "Cash Receipt",
            amount: paymentDetails.convenienceFee + paymentDetails.surcharge,
          });
        }
        if (depositCode.includes(paymentDetails.transaction_type)) {
          applyPaymentInformation.push({
            location: paymentDetails.locationCode,
            invoice: "Deposit",
            depositType: "Cash Receipt",
            amount: paymentDetails.depositAmount,
          });
        }

        const authCode = jsonResponse[0].rawData?.notificationItems[0]?.NotificationRequestItem.additionalData?.authCode;
        
        await sendUsersNotification(
          jsonResponse[0].eventDate,
          jsonResponse[0].paymentMethod,
          lastFour,
          authCode,
          jsonResponse[0].id,
          locationApiRequestInformation,
          paymentDetailInformation,
          applyPaymentInformation,
        );
        const manualPaymentDetailInformation = formattedInvoices
          .filter((inv) => inv.customerId) // Only include invoices with a non-falsy customer ID
          .map((inv) => ({
            reference: inv.id,
            description: "",
            amount: inv.amount,
          }));

        if (paymentDetails.convenienceFee !== 0) {
          manualPaymentDetailInformation.push({
            reference: "Fee",
            description: "Convenience Fee",
            amount: paymentDetails.convenienceFee,
          });
        }
        if (paymentDetails.surcharge !== 0) {
          manualPaymentDetailInformation.push({
            reference: "Fee",
            description: "Surcharge",
            amount: paymentDetails.surcharge,
          });
        }

        if (paymentDetails.depositAmount > 0) {
          const transactionType =
            depositCodeMapping[paymentDetails.transaction_type] || "Deposit";
          const invoiceId = paymentDetails.depositInvoiceId || "No Invoice ID";
          manualPaymentDetailInformation.push({
            reference: "Deposit",
            description: `${transactionType} : ${invoiceId}`,
            amount: paymentDetails.depositAmount,
          });
        }

        await notifyManualEmail(manualPaymentDetailInformation);
        await sendUsersDepositNotification(manualPaymentDetailInformation);
        navigate("/payment-successful");
      }
    } catch (error) {
      console.error("error", error);
      navigate("/error");
    }
  };

  const sendUsersNotification = async (
    paymentDateTime,
    paymentMethod,
    lastFour,
    authCode,
    transactionId,
    locationApiRequestInformation,
    paymentDetailInformation,
    applyPaymentInformation,
  ) => {
    try {
      const emails = paymentDetails.PaymentNotificationEmails ?? [];
      const emailBody = getPaymentReceivedNotification(
        paymentDetails.paymentID,
        paymentDetails.amount,
        paymentDetails.locationCode,
        paymentDetails.shopper_reference,
        paymentDetails.fullName,
        "",
        paymentDateTime,
        paymentMethod,
        lastFour,
        authCode,
        transactionId,
        locationApiRequestInformation,
        paymentDetailInformation,
        applyPaymentInformation,
        paymentDetails.logo,
      );

      if (emails.length > 0) {
        await sendEmailAPI(
          emails,
          "Payment Received Notification",
          emailBody,
          tenantInfo.SendPdfNotification ?? false,
        );
      }
    } catch (error) {
      console.error("error", error);
      throw new Error("There was a problem with send email");
    }
  };

  const sendUsersDepositNotification = async (paymentDetailInformation) => {
    try {
      const emails = paymentDetails.depositNotificationUsers;
      const emailBody = getPaymentReceivedCustomerReceipt(
        paymentDetails.locationCode,
        paymentDetails.paymentID,
        paymentDetails.amount,
        paymentDetailInformation,
        tenantInfo.PaymentContactEmail,
        tenantInfo.PaymentFormContactPhone,
        paymentDetails.logo,
        tenantInfo.HomeUrl,
      );
      if (emails.length > 0) {
        await sendEmailAPI(emails, "Payment Received", emailBody);
      }
    } catch (error) {
      console.error("error", error);
      throw new Error("There was a problem with send email");
    }
  };

  const notifyManualEmail = async (manualPaymentDetailInformation) => {
    try {
      const emailBody = getPaymentReceivedCustomerReceipt(
        paymentDetails.locationCode,
        paymentDetails.paymentID,
        paymentDetails.amount,
        manualPaymentDetailInformation,
        tenantInfo.PaymentContactEmail,
        tenantInfo.PaymentFormContactPhone,
        paymentDetails.logo,
        tenantInfo.HomeUrl,
      );

      await sendEmailAPI([paymentDetails.email], "Payment Received", emailBody);
    } catch (error) {
      throw new Error("There was a problem with send email");
    }
  };
  const updatePaymentStatus = async (
    status,
    paymentStatus,
    adyenPayload,
    adyenResponse,
    dockMasterPayload,
    dockMasterResponse,
    batchPayloadReq,
    batchPayloadRes,
    feeInvoiceId,
    authCode,
    pspReference,
    cardSummary,
    totalAmount,
    batchIds, // Accept batch IDs as a parameter
    referenceNumbers, // Accept reference numbers as a parameter
    locations, // Accept locations as a parameter
  ) => {
    try {
      await updatePaymentAPI(paymentDetails.paymentID, {
        status: status,
        payment_state: paymentStatus,
        adyen_payment_payload: JSON.stringify(adyenPayload),
        adyen_payment_response: JSON.stringify(adyenResponse),
        dockmaster_payment_payload: JSON.stringify(dockMasterPayload),
        dockmaster_payment_response: JSON.stringify(dockMasterResponse),
        batch_payload_req: JSON.stringify(batchPayloadReq),
        batch_payload_response: batchPayloadRes
          ? JSON.stringify(batchPayloadRes)
          : "",
        batch_id: batchIds, // Use the concatenated batch IDs
        referenceNumber: referenceNumbers, // Use the concatenated reference numbers
        location: locations, // Use the concatenated locations
        feeinvoiceid: feeInvoiceId,
        payment_processor: "valPay",
        auth_code: authCode,
        psp_reference: pspReference,
        request_link_code: paymentDetails.paymentLink,
        last_four: cardSummary,
        totalamount: totalAmount,
        primaryemail: paymentDetails.email,
        payment_method:
          adyenResponse.length > 0 ? adyenResponse[0]?.paymentMethod ?? "" : "",
      });
    } catch (error) {
      throw new Error(
        "There was a problem updating the update payment status.",
      );
    }
  };

  const initPayment = async (session) => {
    const checkout = await AdyenCheckout({
      ...configuration,
      session: {
        id: session.id,
        sessionData: session.sessionData,
      },
    });
    checkout.create("dropin").mount(aydenRef.current);
  };

  const createPaymentSession = async () => {
    try {

      const body = {
        amount_value: parseFloat(calculateMinorUnit(paymentDetails.amount)).toFixed(2),
        amount_currency: "USD",
        return_url: `${FRONTEND_URL}/checkout`,
        country_code: paymentDetails.country_code ?? "US",
        shopper_reference: null,
        shopper_email: paymentDetails.shopper_email,
        shopper_locale: paymentDetails.language ?? "en_US",
        shopper_interaction: "Ecommerce",
        submerchant_id: paymentDetails.submerchantId,
        transaction_id: paymentDetails.uuid,
        merchant_id: paymentDetails.merchantId,
        tenant_id: paymentDetails.tenantId + "",
        customer_id: paymentDetails.shopper_reference,
        payment_methods: paymentDetails.payment_methods,
      };


      const jsonResponse = await createPaymentSessionAPI(body);
      setPaymentSession({ ...jsonResponse });
      initPayment(jsonResponse.message);
    } catch (error) {
      navigate("/error");
    }
  };

  const handleRedirectResult = async (sessionId, redirectResult) => {
    const checkout = await AdyenCheckout({
      ...configuration,
      session: {
        id: sessionId,
      },
    });
    checkout.submitDetails({ details: { redirectResult: redirectResult } });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div ref={aydenRef}></div>
    </div>
  );
};

export default Payment;
